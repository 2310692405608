<template>
	<div>
		<label class="mr-3 mb-1" style="display:inline">
			<a v-b-modal="`${policy}-policy-dialog`" href="javascript:;" v-b-tooltip.hover.righttop
				:title="$t(`telestia.policy.${policy}.title`)">{{ $t(`telestia.policy.${policy}.title_short`) }}</a>
		</label>
		<span>
			<font-awesome-icon v-if="isAccepted" :icon="['fas', 'check']" size="lg" style="color:green"/>
			<font-awesome-icon v-if="!isAccepted" :icon="['fas', 'times']" size="lg" style="color:red"/>
		</span>

		<b-modal :id="`${policy}-policy-dialog`" modal-class="policy-dialog" :ref="`${policy}-policy-dialog`" centered scrollable size="lg" header-bg-variant="warning"
			hide-header-close no-close-on-esc no-close-on-backdrop :title="$t(`telestia.policy.${policy}.title`)" footer-class="h5">
			<template #modal-header>
				<h5 class="modal-title">{{ $t(`telestia.policy.${policy}.title`) }}</h5>
				<language-select always-flag="true"/>
			</template>
			<template #default>
				<div class="d-block" v-html="translatedPolicy"></div>
			</template>
			<template #modal-footer="{ hide }">
				<div style="flex:auto">
					<b-checkbox v-model="accepted1" class="d-flex">
						{{ $t(`telestia.policy.accept`, { policy: $t(`telestia.policy.${policy}.title_short`) }) }}
					</b-checkbox>
					<b-checkbox v-if="policy=='personaldata'" v-model="accepted2" class="d-flex">
						{{ $t(`telestia.policy.accept2`) }}
					</b-checkbox>
				</div>
				<div class="d-flex">
					<b-button @click="print" :title="$t('telestia.button.print')"><font-awesome-icon :icon="['fas', 'print']" size="lg"/> {{ $t('telestia.button.print') }}</b-button>
					<b-button class="ml-2" :disabled="policy=='personaldata' && !isAccepted" variant="primary" @click="acceptPolicy(hide)">{{ $t('telestia.button.submit') }}</b-button>
				</div>
			</template>
		</b-modal>
		<iframe id="ifrmPrint" src="" style="display:none;visibility:hidden"></iframe>
	</div>
</template>

<script>
import policyText from './policyText.js';
import LanguageSelect from '../shared/LanguageSelect.vue';

export default {
	name: 'policy',

	props: ['policy'],

	components: {
		LanguageSelect,
	},

	mounted() {
		this.starting = this.accepted1 = this.accepted2 = this.hasAcceptedPolicy;
		if (this.policy=='personaldata' && !this.isAccepted) {
			this.$refs['personaldata-policy-dialog'].show();
		}
	},

	data() {
		return {
			starting: false,
			accepted1: false,
			accepted2: false,
			policyText: policyText,
		}
	},

	computed: {

		selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

		isAccepted() {
			return this.accepted1 && (this.policy=='personaldata' ? this.accepted2 : true);
		},

		hasAcceptedPolicy() {
			return this.$store.getters['user/hasAcceptedPolicy'](this.policy);
		},

		translatedPolicy() {
			let text = this.policyText[this.policy][this.selectedLanguage] ?? this.policyText[this.policy].en;
			text = text.replace('{#NAME}', this.userFullname);
			return text;
		},

		userFullname() { return this.$store.getters['user/getFirstName'] + ' ' + this.$store.getters['user/getLastName']; },

	},

	methods: {

		async acceptPolicy(hide) {
			try {
				if (this.starting !== this.isAccepted) {
					await this.$store.dispatch('user/acceptPolicy', {policy: this.policy, accepted: this.isAccepted});
					this.starting = this.isAccepted;
				}
				hide();
				return Promise.resolve();
			} catch (e) {
				this.$swal({
					title: this.$t('telestia.error.title'),
					text: this.$t('telestia.error.connection'),
					icon: 'error', timer: 5000, button: true
				});
				return Promise.reject(e);
			}
		},

		print() {
			let html = `<h1>${this.$t(`telestia.policy.${this.policy}.title`)}</h1>${this.translatedPolicy}`;
			html += '<scr'+'ipt>window.print();</scr'+'ipt>';
			const ifrmPrint = document.getElementById('ifrmPrint');
			ifrmPrint.setAttribute('src',`data:text/html;charset=utf-8,${html}`);
		},

	},

}
</script>

<style scoped>
</style>

<style>
.custom-control-input {
	width: 20px;
	height: 20px;
	vertical-align: top;
}
.custom-control-label {
	padding: 0 10px;
	vertical-align: top;
	max-width: 90%;
}
.policy-dialog .modal-header {
	flex-wrap: nowrap;
}
li.header::marker {
	font-weight: bold;
}
</style>



