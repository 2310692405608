const policyText =  {
	personaldata: {
		en: `
			<ol>
				<li class="header"><b>Data Controller</b><br>Company: eTelestia<br>
					SITAM-AB, A. Vouyouka & Co<br>Address:<br>3  K. Keramopoulou Str.<br>54622 Thessaloniki<br>Greece
				</li>
				<li class="header"><b>Purpose of Data Processing</b><br>In the context of fulfilling the service contract between {#NAME} and eTelestia, personal data will be collected, stored, and processed. This data processing is necessary to properly provide the services agreed upon in the contract.</li>
				<li class="header">
					<b>Types of Data Collected</b><br>The following personal data may be processed:
					<ul>
						<li>First Name, Last Name</li>
						<li>Address</li>
						<li>EMail Address</li>
						<li>Telephone Number</li>
						<li>Bank Account Details (for payment processing)</li>
						<li>Specific data required for contract fulfillment:5 e.g., body measurements, clothing sizes, individual preferences, and other relevant information for the creation of patterns and garments</li>
						<li>Additional data necessary for fulfilling the contract</li>
					</ul>
				</li>
				<li class="header"><b>Legal Basis</b><br>The processing of personal data is based on Article 6(1)(b) of the GDPR, as it is necessary for the performance of the service contract.</li>
				<li class="header"><b>Data Sharing</b><br>Personal data will only be shared with third parties when necessary for fulfilling the service contract (e.g., with payment service providers, shipping companies). No further sharing of data will occur unless required by law or with the explicit consent of the data subject.</li>
				<li class="header"><b>Data Retention Period</b><br>Data will be retained only as long as necessary to fulfill the service contract or as required by statutory retention periods.</li>
				<li class="header"><b>Rights of the Data Subject</b><br>The data subject has the right to request access to their stored personal data at any time, to request correction or deletion of their data, to restrict processing, or to request data portability. Furthermore, they have the right to revoke any given consent at any time with future effect.</li>
				<li class="header">
					<b>Contact for Data Protection Inquiries</b><br>For questions regarding the processing of personal data or to exercise the above-mentioned rights, the data subject can contact the following address:<br>
					Company: eTelestia<br>
					SITAM-AB, A. Vouyouka & Co<br>Address:<br>3  K. Keramopoulou Str.<br>54622 Thessaloniki<br>Greece<br>Email: contact@etelestia.com
				</li>
				<li class="header"><b>Consent Declaration</b><br>I hereby declare my consent to the processing of my personal data in accordance with the conditions outlined above. I have acknowledged the <a href="https://www.etelestia.com/en/Information-on-Data-Processing-in-Accordance-with-Article-13-GDPR" target="_blank">information obligations</a> according to Articles 13 and 14 of the GDPR.</li>
		`,
		de: `
			<ol>
				<li class="header"><b>Verantwortlicher</b><br>Firma: Fashion-Express.de<br>Adresse: Alte Potsdamer Landstr. 54, D-14532 Stahnsdorf</li>
				<li class="header"><b>Zweck der Datenverarbeitung</b><br>Im Rahmen der Erfüllung des zwischen {#NAME} und Fashion-Express.de geschlossenen Dienstleistungsvertrags werden personenbezogene Daten erhoben, gespeichert und verarbeitet. Diese Datenverarbeitung ist notwendig, um die vertraglich vereinbarten Dienstleistungen ordnungsgemäß zu erbringen.</li>
				<li class="header">
					<b>Art der erhobenen Daten</b><br>Folgende personenbezogene Daten können verarbeitet werden:
					<ul>
						<li>Name, Vorname</li>
						<li>Adresse</li>
						<li>E-Mail-Adresse</li>
						<li>Telefonnummer</li>
						<li>Bankverbindungsdaten (für die Abwicklung von Zahlungen)</li>
						<li>Spezifische Daten für die Vertragserfüllung: Zum Beispiel Körpermaße, Konfektionsgröße, individuelle Vorlieben und andere relevante Angaben für die Erstellung von Schnittmustern und Kleidungsstücken</li>
						<li>Weitere für die Vertragserfüllung notwendige Daten</li>
					</ul>
				</li>
				<li class="header"><b>Rechtsgrundlage</b><br>Die Verarbeitung der personenbezogenen Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, da sie zur Erfüllung des Dienstleistungsvertrags notwendig ist.</li>
				<li class="header"><b>Weitergabe der Daten</b><br>Die personenbezogenen Daten werden ausschließlich an Dritte weitergegeben, soweit dies zur Erfüllung des Dienstleistungsvertrags notwendig ist (z.B. an Zahlungsdienstleister, Versandunternehmen). Eine darüber hinausgehende Weitergabe erfolgt nicht, es sei denn, dies ist gesetzlich vorgeschrieben oder es liegt eine ausdrückliche Einwilligung des Betroffenen vor.</li>
				<li class="header"><b>Speicherdauer</b><br>Die Daten werden nur so lange gespeichert, wie dies für die Erfüllung des Dienstleistungsvertrags erforderlich ist, bzw. wie es die gesetzlichen Aufbewahrungsfristen vorsehen.</li>
				<li class="header"><b>Rechte des Betroffenen</b><br>Der Betroffene hat das Recht, jederzeit Auskunft über die zu seiner Person gespeicherten Daten zu verlangen, deren Berichtigung oder Löschung zu fordern, sowie die Einschränkung der Verarbeitung oder die Datenübertragbarkeit zu verlangen. Des Weiteren steht ihm das Recht zu, eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen.</li>
				<li class="header">
					<b>Kontakt für Datenschutzanfragen</b><br>Bei Fragen zur Verarbeitung personenbezogener Daten oder zur Ausübung der oben genannten Rechte kann sich der Betroffene an folgende Adresse wenden:<br>
					Fashion-Express.de<br>
					Alte Potsdamer Landstr. 54<br>
					D-14532 Stahnsdorf<br>
					E-Mail: [datenschutz@fashion-express.de]
				</li>
				<li class="header"><b>Einverständniserklärung</b><br>Ich erkläre hiermit mein Einverständnis zur Verarbeitung meiner personenbezogenen Daten gemäß den oben genannten Bedingungen. Die <a href="https://www.fashion-express.de/de/Informationen-zur-Datenverarbeitung-gem%C3%A4%C3%9F-Art-13-DSGVO" target="_blank">Informationspflichten</a> nach Art 13 und 14 DSGVO habe ich zur Kenntnis genommen.</li>
			`,
		fr: `
			<ol>
				<li class="header"><b>Responsable du traitement</b><br>Entreprise : eTelestia<br>
					SITAM-AB, A. Vouyouka & Co<br>Addresse :<br>3  K. Keramopoulou Str.<br>54622 Thessaloniki<br>Grèce
				</li>
				<li class="header"><b>Finalité du traitement des données</b><br>Dans le cadre de l'exécution du contrat de service entre {#NAME} et eTelestia, des données personnelles seront collectées, stockées et traitées. Ce traitement des données est nécessaire pour fournir correctement les services convenus dans le contrat.</li>
				<li class="header">
					<b>Types de données collectées</b><br>Les données personnelles suivantes peuvent être traitées :
					<ul>
						<li>Nom, Prénom</li>
						<li>Adresse</li>
						<li>Adresse e-mail</li>
						<li>Numéro de téléphone</li>
						<li>Coordonnées bancaires (pour le traitement des paiements)</li>
						<li>Données spécifiques nécessaires à l'exécution du contrat : par exemple, mensurations, tailles de vêtements, préférences individuelles et autres informations pertinentes pour la création de patrons et de vêtements</li>
						<li>Autres données nécessaires à l'exécution du contrat</li>
					</ul>
				</li>
				<li class="header"><b>Base légale</b><br>Le traitement des données personnelles est basé sur l'article 6(1)(b) du RGPD, car il est nécessaire à l'exécution du contrat de service.</li>
				<li class="header"><b>Partage des données</b><br>Les données personnelles ne seront partagées avec des tiers que lorsque cela est nécessaire à l'exécution du contrat de service (par exemple, avec les prestataires de services de paiement, les sociétés de livraison). Aucun autre partage de données ne sera effectué, sauf si cela est requis par la loi ou avec le consentement explicite de la personne concernée.</li>
				<li class="header"><b>Durée de conservation des données</b><br>Les données seront conservées uniquement aussi longtemps que nécessaire pour exécuter le contrat de service ou comme l'exigent les périodes de conservation légales.</li>
				<li class="header"><b>Droits de la personne concernée</b><br>La personne concernée a le droit de demander à tout moment l'accès à ses données personnelles stockées, de demander la correction ou la suppression de ses données, de restreindre le traitement ou de demander la portabilité des données. De plus, elle a le droit de révoquer tout consentement donné à tout moment avec effet futur.</li>
				<li class="header">
					<b>Contact pour les demandes de protection des données</b><br>Pour toute question concernant le traitement des données personnelles ou pour exercer les droits mentionnés ci-dessus, la personne concernée peut contacter l'adresse suivante :<br>
					Entreprise : eTelestia<br>
					SITAM-AB, A. Vouyouka & Co<br>Addresse :<br>3  K. Keramopoulou Str.<br>54622 Thessaloniki<br>Grèce<br>Email : contact@etelestia.com
				</li>
				<li class="header"><b>Déclaration de consentement</b><br>Je déclare par la présente mon consentement au traitement de mes données personnelles conformément aux conditions décrites ci-dessus. J'ai pris <a href="https://www.etelestia.com/fr/Information-on-Data-Processing-in-Accordance-with-Article-13-GDPR" target="_blank">connaissance des obligations</a> d'information conformément aux articles 13 et 14 du RGPD.</li>
		`,
		el: `
			<ol>
				<li class="header"><b>Υπεύθυνος Επεξεργασίας</b><br>Εταιρεία: eTelestia<br>
					ΣΙΤΑΜ-ΑΒ, Α. Βουγιούκα & ΣΙΑ Ο.Ε.<br>Διεύθυνση:<br>Κ. Κεραμοπούλου 3<br>54622 Θεσσαλονίκη
				</li>
				<li class="header"><b>Σκοπός της Επεξεργασίας Δεδομένων</b><br>Στο πλαίσιο εκπλήρωσης της σύμβασης παροχής υπηρεσιών μεταξύ {#NAME} και της eTelestia, θα συλλεχθούν, αποθηκευτούν και επεξεργαστούν προσωπικά δεδομένα. Αυτή η επεξεργασία δεδομένων είναι απαραίτητη για την ορθή παροχή των συμφωνημένων υπηρεσιών.</li>
				<li class="header">
					<b>Τύποι Δεδομένων που Συλλέγονται</b><br>Μπορούν να επεξεργαστούν τα ακόλουθα προσωπικά δεδομένα:
					<ul>
						<li>Όνομα, Επώνυμο</li>
						<li>Διεύθυνση</li>
						<li>Διεύθυνση Email</li>
						<li>Αριθμός Τηλεφώνου</li>
						<li>Στοιχεία Τραπεζικού Λογαριασμού (για την επεξεργασία πληρωμών)</li>
						<li>Ειδικά δεδομένα που απαιτούνται για την εκπλήρωση της σύμβασης: π.χ. σωματικές διαστάσεις, μεγέθη ρούχων, ατομικές προτιμήσεις και άλλες σχετικές πληροφορίες για τη δημιουργία πατρόν και ενδυμάτων</li>
						<li>Άλλα δεδομένα που είναι απαραίτητα για την εκπλήρωση της σύμβασης</li>
					</ul>
				</li>
				<li class="header"><b>Νομική Βάση</b><br>Η επεξεργασία των προσωπικών δεδομένων βασίζεται στο άρθρο 6(1)(β) του GDPR, καθώς είναι απαραίτητη για την εκτέλεση της σύμβασης παροχής υπηρεσιών.</li>
				<li class="header"><b>Διαβίβαση Δεδομένων</b><br>Τα προσωπικά δεδομένα θα διαβιβάζονται σε τρίτους μόνο όταν είναι απαραίτητο για την εκτέλεση της σύμβασης παροχής υπηρεσιών (π.χ. σε παρόχους υπηρεσιών πληρωμών, εταιρείες μεταφοράς). Δεν θα πραγματοποιείται περαιτέρω διαβίβαση δεδομένων, εκτός εάν απαιτείται από τον νόμο ή με τη ρητή συγκατάθεση του υποκειμένου των δεδομένων.</li>
				<li class="header"><b>Διάρκεια Αποθήκευσης Δεδομένων</b><br>Τα δεδομένα θα αποθηκεύονται μόνο για όσο χρονικό διάστημα είναι απαραίτητο για την εκπλήρωση της σύμβασης παροχής υπηρεσιών ή όπως ορίζεται από τις νομικές περιόδους διατήρησης.</li>
				<li class="header"><b>Δικαιώματα του Υποκειμένου των Δεδομένων</b><br>Το υποκείμενο των δεδομένων έχει το δικαίωμα να ζητήσει ανά πάσα στιγμή πρόσβαση στα αποθηκευμένα προσωπικά του δεδομένα, να ζητήσει τη διόρθωση ή διαγραφή των δεδομένων του, να περιορίσει την επεξεργασία ή να ζητήσει τη φορητότητα των δεδομένων. Επιπλέον, έχει το δικαίωμα να ανακαλέσει οποιαδήποτε συγκατάθεση δοθεί ανά πάσα στιγμή με μελλοντική ισχύ.</li>
				<li class="header">
					<b>Επικοινωνία για Αιτήματα Προστασίας Δεδομένων</b><br>Για ερωτήσεις σχετικά με την επεξεργασία των προσωπικών δεδομένων ή για την άσκηση των προαναφερθέντων δικαιωμάτων, το υποκείμενο των δεδομένων μπορεί να επικοινωνήσει με την ακόλουθη διεύθυνση:<br>
					Εταιρεία: eTelestia<br>
					ΣΙΤΑΜ-ΑΒ, Α. Βουγιούκα & ΣΙΑ Ο.Ε.<br>Διεύθυνση:<br>Κ. Κεραμοπούλου 3<br>54622 Θεσσαλονίκη<br>Email: contact@etelestia.com
				</li>
				<li class="header"><b>Δήλωση Συγκατάθεσης</b><br>Δηλώνω με το παρόν τη συγκατάθεσή μου για την επεξεργασία των προσωπικών μου δεδομένων σύμφωνα με τους παραπάνω όρους. Έχω λάβει γνώση των <a href="https://www.etelestia.com/el/Information-on-Data-Processing-in-Accordance-with-Article-13-GDPR" target="_blank">υποχρεώσεων ενημέρωσης</a> σύμφωνα με τα άρθρα 13 και 14 του GDPR.</li>
		`,
	},
	mediaconsent: {
		en: `
			<p>I hereby declare that I consent to the publication of photos and/or videos of me and/or my work by eTelestia and its partners Fashion Express Learning and Fashion-Express.de for public relations purposes on the internet (www.etelestia.com, www.fashionexpresslearning.co.uk, www.fashion-express.de, Facebook, Twitter, Instagram, Pinterest, TikTok).</p>
			<p>This consent is granted indefinitely unless it is revoked in writing.</p>
			<p>The operator/responsible party of the above-mentioned websites points out that photos/videos published on the internet are accessible worldwide. Therefore, further use of these photos by third parties cannot be generally excluded, and the operator/responsible party assumes no liability for this.</p>
			<p>I am aware that I will not receive any compensation for the publication. The operator/responsible party guarantees that, without the consent of the undersigned, no rights to the photos posted on the internet will be sold, transferred, etc., to third parties. I have received the <a href="https://www.etelestia.com/en/Data-Protection-Notice-in-Accordance-with-Article-13-GDPR" target="_blank">privacy notice</a> in accordance with Article 13 GDPR.</p>
		`,
		de: `
			<p>Hiermit erkläre ich dass ich damit einverstanden bin, dass Fotos und/oder Videos von mir und/oder meinen Arbeiten von Fashion-Express.de und deren Partner eTelestia und FashionExpressLearning zur Öffentlichkeitsarbeit im Internet (www.fashion-express.de, www.etelestia.com, www.fashionexpresslearning.co.uk , Facebook, (X)Twitter, Instagram, Pinterest, Tiktok) veröffentlicht werden dürfen.</p>
			<p>Die Zustimmung ist unbefristet erteilt, sofern sie nicht schriftlich widerrufen wird.</p>
			<p>Der Betreiber/Verantwortliche der oben genannten Website weist darauf hin, dass die Fotos/Videos bei der Veröffentlichung im Internet weltweit abrufbar sind. Eine Weiterverwendung dieser Fotos durch Dritte kann daher nicht generell ausgeschlossen werden, der Betreiber/Verantwortliche übernimmt dafür keine Haftung.</p>
			<p>Mir ist bekannt, dass ich für die Veröffentlichung kein Entgelt erhalte. Der Betreiber/Verantwortliche sichert zu, dass ohne Zustimmung des Unter- zeichnenden Rechte an den in das Internet eingestellten Fotos nicht an Dritte veräußert, abgetreten usw. werden. Die <a href="https://www.fashion-express.de/de/Datenschutzhinweise-gem%C3%A4%C3%9F-Art-13-DSGVO" target="_blank">Datenschutzhinweise</a> gemäß Art. 13 DSGVO habe ich erhalten.</p>
		`,
		fr: `
			<p>Je déclare par la présente que j'accepte que des photos et/ou vidéos de moi et/ou de mon travail soient publiées par eTelestia et ses partenaires Fashion Express Learning et Fashion-Express.de à des fins de relations publiques sur Internet (www.etelestia.com, www.fashionexpresslearning.co.uk, www.fashion-express.de, Facebook, Twitter, Instagram, Pinterest, TikTok).</p>
			<p>Ce consentement est accordé pour une durée indéterminée, sauf s'il est révoqué par écrit.</p>
			<p>L'opérateur/responsable des sites web mentionnés ci-dessus signale que les photos/vidéos publiées sur Internet sont accessibles dans le monde entier. Par conséquent, une réutilisation de ces photos par des tiers ne peut être généralement exclue, et l'opérateur/responsable n'assume aucune responsabilité à cet égard.</p>
			<p>Je suis conscient(e) que je ne recevrai aucune rémunération pour cette publication. L'opérateur/responsable garantit que, sans le consentement du signataire, aucun droit sur les photos publiées sur Internet ne sera vendu, transféré, etc., à des tiers. J'ai reçu <a href="https://www.etelestia.com/fr/Data-Protection-Notice-in-Accordance-with-Article-13-GDPR" target="_blank">l'avis de confidentialité conformément</a> à l'article 13 du RGPD.</p>
		`,
		el: `
			<p>Δηλώνω με το παρόν ότι συμφωνώ με τη δημοσίευση φωτογραφιών και/ή βίντεο που απεικονίζουν εμένα και/ή το έργο μου από την eTelestia και τους συνεργάτες της Fashion Express Learning και Fashion-Express.de για σκοπούς δημοσίων σχέσεων στο διαδίκτυο (www.etelestia.com, www.fashionexpresslearning.co.uk, www.fashion-express.de, Facebook, Twitter, Instagram, Pinterest, TikTok).</p>
			<p>Η συγκατάθεση αυτή παρέχεται επ' αόριστον, εκτός εάν ανακληθεί εγγράφως.</p>
			<p>Ο διαχειριστής/υπεύθυνος των παραπάνω ιστότοπων επισημαίνει ότι οι φωτογραφίες/βίντεο που δημοσιεύονται στο διαδίκτυο είναι προσβάσιμες παγκοσμίως. Ως εκ τούτου, δεν μπορεί να αποκλειστεί γενικά η περαιτέρω χρήση αυτών των φωτογραφιών από τρίτους, και ο διαχειριστής/υπεύθυνος δεν αναλαμβάνει καμία ευθύνη γι' αυτό.</p>
			<p>Είμαι ενήμερος/η ότι δεν θα λάβω καμία αμοιβή για τη δημοσίευση. Ο διαχειριστής/υπεύθυνος εγγυάται ότι, χωρίς τη συγκατάθεση του υπογράφοντος, δεν θα πωληθούν, μεταβιβαστούν, κ.λπ., σε τρίτους δικαιώματα επί των φωτογραφιών που αναρτώνται στο διαδίκτυο. Έχω λάβει τη <a href="https://www.etelestia.com/el/Data-Protection-Notice-in-Accordance-with-Article-13-GDPR" target="_blank">δήλωση προστασίας προσωπικών δεδομένων</a> σύμφωνα με το άρθρο 13 του GDPR.</p>
		`,
	}
};

export default policyText;

