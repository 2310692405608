<template>
	<div id="measurements">

		<div class="divider w-100"></div>

		<!-- Measurement section -->
		<div class="row mx-0 mb-4">
			<div class="col-12 col-lg-8 mx-auto">
				<h5 class="mb-2 font-weight-bold">{{ $t('telestia.title.measurements') }}</h5>

				<div class="row mb-2">
					<div class="col">
						<div class="table-responsive">
							<table class="table">
								<thead>
									<tr>
										<th></th>
										<th class="name">{{ $t('telestia.table.measurements.name') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_chest') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_bust') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_chbd') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_tbodl') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_bl') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_bd') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_wm') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_hm') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_hwd') }}</th>
										<th class="text-center">{{ $t('telestia.table.measurements.h_hl') }}</th>
										<th class="actions"></th>
									</tr>
								</thead>
								<tbody>
									<!-- Error loading profile -->
									<tr v-if="measurementError">
										<td colspan="12">{{ measurementError }}</td>
									</tr>

									<template v-else>
										<tr v-for="measurement in measurements" :key="measurement.id" class="align-middle">
											<td><font-awesome-icon v-if="measurement.is_default === 1" :icon="['fas', 'check-circle']" class="ml-2 text-blue" /></td>
											<td class="name text-ellipsis text-nowrap" style="max-width:250px">{{ measurement.name }}</td>
											<td class="text-center">{{ calcValue(measurement.TCM / 2, measurementForm.TCM.div) }}</td>
											<td class="text-center">{{ calcValue(measurement.TBM / 2) }}</td>
											<td class="text-center">{{ calcValue(calcValue(measurement.TBM/2) - calcValue(measurement.TCM/2,{round:2})) }}</td>
											<td class="text-center">{{ calcValue(measurement.TBL / 2, measurementForm.TBL.div) }}</td>
											<td class="text-center">{{ measurement.BL }}</td>
											<td class="text-center">{{ calcValue(measurement.BD / 2, measurementForm.BD.div) }}</td>
											<td class="text-center">{{ calcValue(measurement.WM / 4, {decimals:1,round:0.5}) }}</td>
											<td class="text-center">{{ calcValue(measurement.HM / 4, {decimals:1,round:0.5}) }}</td>
											<td class="text-center">{{ Math.floor(calcValue(calcValue(measurement.HM/4,{round:0.5,decimals:1}) - calcValue(measurement.WM/4,{round:0.5,decimals:1}), {decimals: 1})) }}</td>
											<td class="text-center">{{ measurement.HL }}</td>
											<td class="actions">
												<div class="d-flex">
													<font-awesome-icon :icon="['fas', 'print']" size="lg" class="text-blue mouse-pointer ml-2"
														@click="printMeasurement(measurement)" :title="$t('telestia.button.print')" />
													<font-awesome-icon v-b-modal.measurements-form :icon="['fas', 'edit']" size="lg" class="text-blue mouse-pointer ml-2"
														@click="prepareMeasurementEdit(measurement)" :title="$t('telestia.button.edit')" />
													<font-awesome-icon :icon="['fas', 'trash-alt']" size="lg" class="text-blue mouse-pointer ml-2"
														@click="deleteMeasurement(measurement.id)" :title="$t('telestia.button.delete')" />
												</div>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col d-flex justify-content-end">
						<button class="primary-btn p-2" v-b-modal.measurements-form>{{ $t('telestia.button.create') }}</button>
					</div>
				</div>
			</div>
		</div>



		<b-modal id="measurements-form" centered scrollable size="lg" hide-header-close @hidden="resetForm">
			<template #modal-header>
				<div class="w-100 d-flex justify-content-between align-items-center">
					<h5>{{ $t('telestia.title.measurement') }}</h5>
					<div class="d-flex justify-content-end align-items-center">
						<label class="mr-2">{{ $t('telestia.title.default') }}:</label>

						<input
							type="checkbox"
							class="form-input-check mr-2"
							:id="`checkbox-${Object.keys(measurementForm).length-1}`"
							v-model="measurementForm.is_default.value"
						/>
					</div>
				</div>
				<div class="flex-break"></div>
				<div class="w-100">{{ $t('telestia.table.measurements.note') }}</div>
			</template>

			<template #default>
				<div v-for="(field) in Object.keys(measurementForm)" :key="field">
					<div v-if="field !== 'id' && measurementForm[field].type !== 'checkbox'" class="mb-2 row d-flex align-items-center">
						<label :for="field" class="col-sm-4 col-form-label">{{ $t(`telestia.table.measurements.${measurementForm[field].label}`) }}:</label>

						<div class="col-sm-8">
							<div v-if="measurementForm[field].type === 'number'" class="row d-flex align-items-center">
								<div class="col">
									<input v-if="measurementForm[field].calculate" class="form-control" :value="measurementForm[field].calculate()" readonly />
									<input v-else type="number" min="0" max="999" class="form-control" :id="field" v-model.number="measurementForm[field].value"
										maxlength="5" onclick="select()"
										:onkeypress="`return (/[\\d`+ (isMeasurement(field) ? '' : '\.') +`]/).test(event.key)`"
										@input="restrictInput($event,measurementForm[field].value,`/[\\d`+ (isMeasurement(field) ? '' : '\.') +`]/`)"
									/>
								</div>
								<div class="col text-center">
									<span v-if="((measurementForm[field].div||{}).by||'').includes('2')" :class="{'font-weight-bold' : ['TCM','HM'].includes(field)}">
										{{ calcValue(measurementForm[field].value / 2, { ...{decimals:1,round:0.5}, ...measurementForm[field].div}) }}
									</span>
								</div>
								<div class="col text-center">
									<span v-if="((measurementForm[field].div||{}).by||'').includes('4')">
										{{ calcValue(measurementForm[field].value / 4, { ...measurementForm[field].div, ...{decimals:1,round:0.5} }) }}
									</span>
								</div>
								<div class="col text-center">
									<span v-if="computedMeasurements[field] != null" :class="{'font-weight-bold' : ['TBM','HM'].includes(field)}">
										{{ computedMeasurements[field] }}
									</span>
								</div>
							</div>

							<input v-else :id="field"
								:type="measurementForm[field].type"
								class="form-control"
								v-model="measurementForm[field].value"
								maxlength="100"
							/>
						</div>
					</div>
					<div v-if="field=='name'">
						<div class="mb-2 row d-flex align-items-center">
							<label class="col-sm-4 col-form-label"><b>{{ $t('telestia.table.measurements.measurement') }}</b></label>
							<div class="col-sm-8">
								<div class="row d-flex align-items-center">
									<div class="col"><b>{{ $t('telestia.table.measurements.value') }}</b></div>
									<div class="col text-center"><b>1/2</b></div>
									<div class="col text-center"><b>1/4</b></div>
									<div class="col"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</template>

			<template #modal-footer="{ hide }">
				<div>
					<button class="primary-btn py-2 px-3 mr-2" @click="printMeasurement()">{{ $t('telestia.button.print') }}</button>
					<button class="primary-btn py-2 px-3 mr-2" @click="saveMeasurement({ hide })"
						:disabled="!measurementForm.name.value.trim()" :class="{disabled:!measurementForm.name.value.trim()}">
						{{ $t('telestia.button.ok') }}
					</button>
					<button class="primary-btn p-2" @click="hide()">{{ $t('telestia.button.cancel') }}</button>
				</div>
			</template>
		</b-modal>

		<iframe id="ifrmPrint" src="" style="display:none;visibility:hidden"></iframe>
		<!--<iframe id="ifrmPrint" src="" style="width:100%;height:400px"></iframe>-->
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _clamp from 'lodash/clamp';

export default {
	name: "personal-measurements",

	props: ['measurements', 'measurementError'],

	created() {
	},

	/*
	CALCULATIONS
	Sizes (1/2 chest, 1/2 HM): rounded to nearest even integer, favouring highest size (round to lowest for 1 cm up, round to highest for 2 or 3 cm up)
	1/2 values: "measurements" rounded to nearest integer / others 1 decimal, rounded to nearest 0.5
	1/4 values: 1 decimal, rounded to nearest 0.5
	ChBD: calculate from 1/2 values
	HWD: calculate from 1/4 values, rounded to lowest integer
	*/

	data() {
		let instance = this;
		return {
			measurementForm: {
				id: { label: 'id', value: '', type: 'text' },
				name: { label: 'name', value: '', type: 'text' },
				TCM: { label: 'total_chest_measurement', value: '', type: 'number', div: { by: '2', round: 2 } },
				TBM: { label: 'total_bust_measurement', value: '', type: 'number', div: { by: '2', round: 1 } },
				SDF: { label: 'shoulder_distance_front', value: '', type: 'number', div: { by: '2', round: 0.5, decimals: 1 } },
				SDB: { label: 'shoulder_distance_back', value: '', type: 'number', div: { by: '2', round: 0.5, decimals: 1 } },
				TBL: { label: 'total_bodice_length', value: '', type: 'number', div: { by: '2', round: 0.5, decimals: 1 } },
				BLF: { label: 'bodice_length_front', value: '', type: 'number', calculate(){
					return this.value = (instance.measurementForm.TBL.value||0) - (instance.measurementForm.BLB.value||0);
				}},
				BLB: { label: 'bodice_length_back', value: '', type: 'number' },
				BL: { label: 'bust_length', value: '', type: 'number' },
				BD: { label: 'bust_distance', value: '', type: 'number', div: { by: '2', round: 0.5, decimals: 1 } },
				SL: { label: 'sleeve_length', value: '', type: 'number' },
				WM: { label: 'waist_measurement', value: '', type: 'number', div: { by: '2 4', round: 1 } },
				HM: { label: 'hip_measurement', value: '', type: 'number', div: { by: '2 4', round: 2 } },
				Slant: { label: 'slant', value: '', type: 'number', calculate(){
					return this.value = _clamp(instance.calcValue((instance.computedMeasurements.HM||0)/2,{decimals:1}),0,3);
				}},
				UHM: { label: 'upper_hip_measurement', value: '', type: 'number', div: { by: '2 4', round: 1 } },
				HL: { label: 'hip_length', value: '', type: 'number' },
				SkL: { label: 'skirt_length', value: '', type: 'number' },
				TL: { label: 'trouser_length', value: '', type: 'number' },
				is_default: { label: 'default', value: false, type: 'checkbox' }
			},
			measurementFormType: 'create',
		};
	},

	computed: {

		// Current measurement with all computed fields filled
		computedMeasurements() {
			const measures = [];
			Object.keys(this.measurementForm).forEach(k => {
				switch (k) {
					case 'TCM':
						measures[k] = this.$t('telestia.table.measurements.chbd');
						break;
					case 'TBM': {
						const chbd = this.calcValue(this.calcValue(this.measurementForm.TBM.value/2) - this.calcValue(this.measurementForm.TCM.value/2, {round:2}), {decimals: 1});
						measures[k] = chbd;
						break;
					}
					case 'SDF':
						measures[k] = this.$t('telestia.table.measurements.shdd');
						break;
					case 'SDB': {
						const shdd = this.calcValue((this.measurementForm.SDB.value / 2) - (this.measurementForm.SDF.value / 2), {decimals: 1});
						measures[k] = shdd;
						break;
					}
					case 'BLF':
						measures[k] = this.$t('telestia.table.measurements.bld');
						break;
					case 'BLB': {
						const bld = this.calcValue((this.measurementForm.TBL.value / 2) - this.measurementForm.BLB.value, {decimals: 1});
						measures[k] = bld;
						break;
					}
					case 'WM':
						measures[k] = this.$t('telestia.table.measurements.hwd');
						break;
					case 'HM': {
						const hwd = Math.floor(this.calcValue(this.calcValue(this.measurementForm.HM.value/4, {round:0.5,decimals:1}) - this.calcValue(this.measurementForm.WM.value/4, {round:0.5,decimals:1}), {decimals: 1}));
						measures[k] = hwd;
						break;
					}
					default:
						measures[k] = '';
				}
			});
			return measures;
		},

		organisation()  { return this.$store.getters['userOrganisation/getOrganisation'] },

	},

	methods: {

		// Reset form
		resetForm() {
			// Reset form fields
			Object.keys(this.measurementForm).forEach(k => {
				if (this.measurementForm[k].type === 'checkbox') {
					this.measurementForm[k].value = false;
				} else {
					this.measurementForm[k].value = '';
				}
			});
			// Turn the form mode into create
			this.measurementFormType = 'create';
		},

		prepareMeasurementEdit(measurementToUpdate) {
			this.measurementFormType = 'edit';
			Object.keys(this.measurementForm).forEach(k => this.measurementForm[k].value = measurementToUpdate[k]);
		},

		// Save new or updated measurement
		async saveMeasurement({ hide }) {
			try {
				if (this.measurementFormType === 'create') {
					await this.addMeasurement({ hide });
				} else {
					await this.editMeasurement({ hide });
				}
			} catch (e) {
				console.log(e);
			}
		},

		cleanupMeasurements(){
			let measurements = _cloneDeep(this.measurementForm)
			Object.keys(measurements).forEach(k => {
				if (this.measurementForm[k].type === 'checkbox')  {
					measurements[k] = this.measurementForm[k].value ? 1 : 0;
				} else {
					measurements[k] = this.measurementForm[k].value;
				}
				if (k!='is_default' && !measurements[k]) delete measurements[k];
			});
			return measurements;
		},

		async addMeasurement({ hide }) {
			let newMeasurement = this.cleanupMeasurements();
			try {
				const newMeas = await this.$store.dispatch('measurements/addUserMeasurement', newMeasurement);
				if (newMeas.is_default) this.measurements.forEach(val => val.is_default = false);
				this.measurements.push(newMeas);
				this.showAlert({ title: this.$t('telestia.profile.measure_save'), text: '', icon: 'success' });
				return Promise.resolve();
			} catch (e) {
				this.showAlert({
					title: this.$t('telestia.error.title'),
					text: this.$t('telestia.error.measure_save') + "\n" + this.$t('telestia.error.connection'),
					icon: 'error',
				});
				return Promise.reject(e);
			} finally {
				hide();
			}
		},

		async deleteMeasurement(measurementId) {
			try {
				const shouldDelete = await this.$swal({
					title: this.$t('telestia.profile.measure_delete_confirm'),
					text: '', icon: 'warning',
					buttons: [this.$t('telestia.button.cancel'),this.$t('telestia.button.yes')],
					dangerMode: true,
				});
				if (!shouldDelete) return;

				await this.$store.dispatch('measurements/deleteUserMeasurement', measurementId);
				this.measurements = this.measurements.filter(m => m.id !== measurementId);

				this.showAlert({ title: this.$t('telestia.profile.measure_delete'), text: '', icon: 'success' });
			} catch (e) {
				this.showAlert({
					title: this.$t('telestia.error.title'),
					text: this.$t('telestia.error.measure_delete') + "\n" + this.$t('telestia.error.connection'),
					icon: 'error',
				});
				console.log(e);
			}
		},

		async editMeasurement({ hide }) {
			let updatedMeasurement = this.cleanupMeasurements();

			try {
				const updatedMeas = await this.$store.dispatch('measurements/editUserMeasurement', updatedMeasurement);
				if (updatedMeas.is_default) this.measurements.forEach(val => val.is_default = false);
				const updatedMeasurementIndex = this.measurements.findIndex(m => parseInt(m.id) === parseInt(updatedMeas.id));
				this.$set(this.measurements, updatedMeasurementIndex, updatedMeas);
				this.showAlert({ title: this.$t('telestia.profile.measure_update'), text: '', icon: 'success' });
				return Promise.resolve();
			} catch (e) {
				this.showAlert({
					title: this.$t('telestia.error.title'),
					text: this.$t('telestia.error.measure_update') + "\n" + this.$t('telestia.error.connection'),
					icon: 'error',
				});
				return Promise.reject(e);
			} finally {
				hide();
			}
		},

		showAlert({ title, text, icon }) {
			this.$swal({ title, text, icon, timer: 5000, button: true });
		},

		calcValue(val=0, { round=0, decimals=0, threshold=0 } = {}) {
			let res = parseFloat(val)-threshold;
			if (round!=0) res = Math.round(res / round) * round;
			return Number(res.toFixed(decimals));
		},

		async printMeasurement(measurement) {
			if (measurement) this.prepareMeasurementEdit(measurement);
			let logo = this.organisation.logo;
			if (!logo) {
				const data = await fetch(require('../../assets/images/logo.png'));
				logo = Buffer.from(await data.arrayBuffer()).toString('base64');
			}
			var html = `
				<style>
					*{font-family:Roboto,sans serif}
					table{border-collapse:collapse}
					.val{border-right:2px solid black}
					th{border-bottom:2px solid black}
					td,th{min-width:100px;height:30px}
				</style>
				<img src="data:image/png;charset=utf-8;base64,${logo}" style="max-height:100px" />
				<hr>
				<h1>${this.measurementForm.name.value}</h1>
				<p>${this.$t('telestia.table.measurements.note')}</p>
				<table>
					<tr>
						<th align="left">${this.$t('telestia.table.measurements.measurement')}</th>
						<th class="val" align="center">${this.$t('telestia.table.measurements.value')}</th>
						<th align="center">1/2</th><th align="center">1/4</th><th></th>
					</tr>
			`;
			Object.keys(this.measurementForm).forEach(k => {
				if (['id','name','is_default'].includes(k)) return;
				let item = this.measurementForm[k];
				html += `
					<tr>
						<td>${this.$t(`telestia.table.measurements.${item.label}`)}</td>
						<td class="val" align="center">${this.calcValue(item.value)}</td>
						<td align="center" style="${['TCM','HM'].includes(k)?'font-weight:bold':''}">
							${(item.div?.by||'').includes('2') ? this.calcValue(item.value / 2, item.div) : ''}
						</td>
						<td align="center">${(item.div?.by||'').includes('4') ? this.calcValue(item.value / 4, { ...item.div, ...{round:0.5,decimals:1} }) : ''}</td>
						<td align="center" style="${['TBM','HM'].includes(k)?'font-weight:bold':''}">
							${this.computedMeasurements[k]}
						</td>
					</tr>
				`;
			});
			html += '</table>';
			html += '<scr'+'ipt>window.print();</scr'+'ipt>';
			const ifrmPrint = document.getElementById('ifrmPrint');
			ifrmPrint.setAttribute('src',`data:text/html;charset=utf-8,${html}`);
		},

		restrictInput(event, value, regex) { value=value.toString().replace(regex,'').slice(0,event.target.maxLength); },

		isMeasurement(field) { return this.measurementForm[field].label.includes('measurement'); },

	},

	watch: {
	},
}
</script>

<style scoped>
.disabled {
	opacity: 0.5;
	text-decoration: none;
	cursor: grab !important;
	pointer-events: none;
}
.text-link:hover {
	color: #1b96cf;
}

.flex-break {
	flex-basis: 100%;
	height: 0;
}

tr .name,
tr .actions {
	position: sticky;
	background: white;
}
tr .name { left: 0 }
tr .actions { right: 0 }

.form-control[readonly] {
	border: 0;
	background: transparent;
}
.form-control[readonly]:focus {
	border: 0;
	box-shadow: none;
}
</style>

<style>
.modal-header {
	flex-wrap: wrap;
}
</style>