<template>
	<div id="you-may-also-like" v-if="visible && isNotOfflineBuild && isNotAffiliate">

		<div class="divider w-100"></div>

		<div class="row mx-0 mb-4">
			<div class="col-12 col-lg-8 mx-auto">
				<h5 class="mb-2 font-weight-bold">{{ $t('telestia.title.youmayalsolike') }}</h5>
			</div>
		</div>
		<div class="row mx-0 mb-4">
			<div class="col-12 col-lg-8 mx-auto">
				<div class="row mx-0 mb-4 d-flex justify-content-center align-items-center">
					<div v-for="(course, key) in visibleCourses" class="ymal d-flex flex-column mx-auto text-center" :key="key" style="width:auto">
						<a :href="course.url" target="_blank">
							<img class="mb-4" :src="require(`../../assets/images/you-may-also-like/${key}.jpg`)">
						</a>
						<h5><span v-html="course[selectedLanguage] || course.en"></span></h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "you-may-also-like",

	props: ['usercourses'],

	data() {
		return {
			courses: {
				pm: {
					visible: true,
					en: 'Online Pattern<br>Making Course',
					de: 'Schnittkonstruktion<br>online Kurs',
					el: 'Κοπτική<br>Πατρόν',
					fr: 'Cours de Modélisme<br>en ligne',
				},
				pgr: {
					visible: true,
					en: 'Online Pattern<br>Grading Course',
					de: 'Gradierung<br>online Kurs',
					el: 'Κοπτική<br>Μεγεθύνσεις',
					fr: 'Cours de Gradation<br>en ligne',
				},
				se: {
					visible: true,
					en: 'Learn how<br>to sew',
					de: 'Lernen Sie<br>Nähen',
					el: 'Ραπτική',
					fr: 'Apprenez à<br>Coudre',
				},
				fd: {
					visible: true,
					en: 'Fashion Design<br>Course Online',
					de: 'Modedesign<br>online Kurs',
					el: 'Μέθοδος<br>Σχεδίου Μόδας',
					fr: 'Cours de Stylisme<br>en Ligne',
				},
				tc: {
					visible: true,
					en: 'Telestia Creator<br>CAD software',
					de: 'Telestia Creator<br>CAD',
					el: 'Telestia Creator<br>Λογισμικό CAD',
					fr: 'Telestia Creator<br>Logiciel CAO',
				},
			},
		};
	},

	async created() {
		const urls = this.$store.getters['userOrganisation/getOrganisation']?.youmayalsolike || {};
		Object.entries(urls).map(([key,val]) => {
			if (this.courses[key]) {
				this.courses[key].url = val;
			}
		});

		this.usercourses?.forEach(val => {
			const key = this.cdId2cdKey(val.cdId);
			if (this.courses[key]) {
				this.courses[key].visible = false;
			}
		});
	},

	computed: {
		visible() { return !!Object.entries(this.courses).filter(([k,v]) => v.visible && v.url).length },
		selectedLanguage() { return this.$store.getters['user/getSelectedLanguage'] },
		visibleCourses() { return Object.fromEntries(Object.entries(this.courses).filter(([k,v]) => v.visible && v.url).sort(()=>Math.random()-0.5)) },
		isNotOfflineBuild() { return !this.$store.getters['app/isOffline'] },
		isNotAffiliate() { return !this.$store.getters['userOrganisation/getOrganisation']?.affiliate },
	},

	methods: {
		cdId2cdKey(id) {
			const conv = {1:'pm', 2:'fd', 3:'se', 4:'pgr', 9:'tc'};
			return conv[id];
		},
	},

}
</script>

<style scoped>
.ymal img {
	width: 120px;
	border-radius: 120px;
}
</style>